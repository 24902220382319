import React from 'react';
import { BrowserRouter as Router, Route, Link, NavLink, Switch, Redirect } from 'react-router-dom';
import { 
  Row,
  Col,
  Container
} from 'react-bootstrap';

import '~/Common.scss';
import '~/SidebarMenu.scss';

// Components
import FSLTopNavbar from './TopNavbar';
import FSLSideNavbar from './SideNavbar';

// Pages
import Home from '~/pages/PgHome';
import About from '~/pages/PgAbout';
import ProjectsIndex from '~/pages/PgProjects';
import Project1 from '~/soundlab_articles/P1';
import Project2 from '~/soundlab_articles/P2';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() { 
    return (
      <div className='app-root fa-bg-light fa-full-page-height'>
        <Container xl>
        <header className='banner'>
          <FSLTopNavbar />
        </header>
        <body>
          <Router>
            <Switch>
              <Route exact path='/home' component={Home} />
              <Route exact path='/about' component={About} />
              <Route exact path='/projects' component={ProjectsIndex} />
              <Route exact path='/p1' component={Project1} />
              <Route exact path='/p2' component={Project2} />
              <Route path='/'>
                <Redirect to='/home'/>
              </Route>
            </Switch>
          </Router>
        </body>
        {/*
        <footer className='footer bg-gradient-light'>
          <p>2022 Fritz Acoustics</p>
        </footer>
        */}
        </Container>
      </div>
    )
  };
}



export default App;
