import React from 'react';
import '~/Common.scss';
import PgProjectOutline from '~/pages/PgProjectOutline';

function Project1(props) {
  return (
    <PgProjectOutline header="Project 1: Fritz Acoustics 3 Way Speaker Design">
      <p className='text-center'>
        <a href="/projects">
          <img className='img-sm-resize' src="/images/projects/first-speaker/SpeakerWire.png" alt="Speaker Wireframe Header" width="400" />
          <h3 className='text-center'>Loudspeaker Project</h3>
        </a>
      </p>
      <p className='pt-3'>I'm just writing random ideas here. Hopefully it will form into a cohesive story at some point.</p>
      <p className='pt-3'>When first designing active crossovers, I wanted to make the most of the PCBs that I was buying, so I started with the philosophy that I should make the system as customizable as possible. This led to a design where I had a "busboard" which routed the audio signals and power through a series of small daughter boards, and each daughter board could be one of a number of different filters. I designed small plugin PCBs for every kind of filter I might need, including high pass/low pass, notch filters, buffers, etc. I used Linkwitz Lab's active filter schematics as a guideline.</p>
      <p className='pt-3'>The modular design proved to be helpful for prototyping. But when I started making  more than a couple, it became time-consuming to solder so many connectors, and jumpers over unused slots. The whole assembly takes up a lot of space, especially in small designs where only a couple of modules were actually needed. In addition, I had concerns about the modules falling out or breaking off inside the speaker, as I was using cheap 0.1" headers to secure the modules.</p>
      <p className='pt-3'>So after I prototyped a filter configuration that I liked for this particular project, I went ahead and designed a PCB with only the filters needed, all integrated onto one board. No more assembly required, and no more concerns of reliability due to physical connections breaking over time. The resulting crossover section of the PCB looks like this:</p>
      <div className='text-center'><img className='img-sm-resize' src="/images/projects/first-speaker/xo-sect.png" alt="Analog Active XO" width="400" /></div>
      <p className='pt-3'>Each 1" square block of components is a filter, and each filter performs it's task independently to shape the overall response of the speaker system. In the center of each filter is an OpAmp chip, either containing one or two amplifiers. These amplifiers drive the passive components around them. The active filters use a combination of capacitors and resistors to shape the AC signal passing through.</p>
      <p className='pt-3'>This design fulfills the requirements of being relatively inexpensive with high performance. Objectively as far as speaker designs go, a well-designed active system can be among the best value because of the superb sound that you can get from a system where each part contributes equally to the end result, not relying on any one "super" component to get the best performance.</p>
      <p className='pt-3'>However, there is another approach that brings it's own benefits of compactness, and customizability. DSP systems with enough power to perform as a loudspeaker crossover is now available to the average user in the Analog Devices ADAU1701. </p>
      <div className='text-center'><img className='img-sm-resize' src="/images/projects/first-speaker/adau1701-dsp-audio-digital-signal-processor-kernel-board.jpg" alt="DSP XO" width="400" /></div>
      <p className='pt-3'>This chip is fully configurable, easily with software provided by Analog Devices. The result is a dynamically configurable and compact speaker system, with the capability of making your speakers smarter, and more adaptable to more use cases.</p>
    </PgProjectOutline>
  )
}

export default Project1;