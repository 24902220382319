import React from 'react';
import '~/Common.scss';
import { 
  Row,
  Col,
  Container
  } from 'react-bootstrap';

function PgProjectOutline(props) {
  return (
    <React.Fragment>
      <div className='page-header-bar shadow-lg'>
        {props.header}
      </div>
      <Container>
        <Row noGutters='true'>
          <Col className='pt-5'>
            <Row className='m-4'>
              <Col className='w-100 p-3 shadow rounded'>
                {props.children}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default PgProjectOutline;