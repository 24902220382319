import React from 'react';
import '~/Common.scss';
import PgProjectOutline from '~/pages/PgProjectOutline';

function Project2(props) {
  return (
    <PgProjectOutline header='Project 2: Javascript Intro'>
      <p className='text-center'>
        <a href="/projects">
          <img className='img-sm-resize' src="/images/projects/jsintro/jscup.png" alt="Java (script)" width="400" />
          <h3>Javascript Intro for Programmers</h3>
        </a>
      </p>
      <p className='pt-3'>
        I had a tough time learning JavaScript. There are so many misconceptions about what it is and how it works, that even the most experienced programmers will have to step back and open their minds to accept that they might need to un-learn a few things in order to “get” JavaScript. Based on my experience in learning JavaScript, I want to walk you through five realizations that I had, at which point my learning greatly accelerated. Look at it this way, once you learn these five points, you’ve already fought more than half the battle. The rest will just be details. Let’s get on with it!
      </p>
      <p className='pt-3'></p>
      <h5>
        1. JavaScript is NOT Java.
      </h5>
      <p className='pt-3'>
        JavaScript is the world’s most misunderstood language. (http://www.crockford.com/javascript/javascript.html) The very first point that I just (sensibly) assumed, was that JavaScript was just a shorthand version of Java. This couldn’t be further from the case. It is a totally different runtime environment, different syntax, different flow of execution, different everything.
      </p>
      <h5>
        2. Arrow functions, callback functions, pure functions, functions, functions!
      </h5>
      <p className='pt-3'>
        There is a whole new world of possibility when you add in the flexibility JavaScript gives you when defining and using functions. But this world of possibility comes with a bit of confusion. Do yourself a favor and learn the six ways to define a function in JavaScript, and you’ll know what you’re looking at no matter what you encounter. Chances are you will find a couple of favorites, but if you’re looking at someone else’s code, they can use whichever one you want. Note that each type of function definition has its own special properties, and sometimes it DOES matter which one you use!  (https://dmitripavlutin.com/6-ways-to-declare-javascript-functions/)
      </p>
      <p className='pt-3'>
        Callback functions get a special shout out here, because they have a special use in JavaScript. As you’ll see below, some functions in JavaScript “execute later.” What this means is that your program calls a function, and then before it completes, your program happily continues on.
      </p>
      <h5>
        3. You need to know about JSON
      </h5>
      <p className='pt-3'>
        There are three steps to understanding JSON. The first and easiest step is to know that Java Script Object Notation is just the syntax. An object in JavaScript is simply a grouping of key-value pairs. The keys are commonly called “properties” or “props” for short. These props are usually strings, but can be anything really. The same goes for the values. 
      </p>
      <p className='pt-3'>
        Second: understand that when you put a JSON object into a variable, that variable is only a POINTER to the object!! This is usually called a “reference.” This means that the variable holding a JavaScript object is a constant value, while the object itself can change. This causes endless confusion when passing JavaScript objects as arguments to a function, or when trying to copy JSON objects. Be very careful when trying to copy a JavaScript object. You are most likely going to end up copying the reference, and then you will end up modifying the object from two different places. This is almost never the desired behavior. Unfortunately, copying a JavaScript object is not easy, and you will usually have to rely on one of a number of libraries to help you do the copy, else you are reinventing the (complicated) wheel.
      </p>
      <ul>
        <li>(https://www.digitalocean.com/community/tutorials/copying-objects-in-javascript)</li>
        <li>(https://flaviocopes.com/how-to-clone-javascript-object/)</li>
      </ul>
      <p className='pt-3'>
      Third is to understand all of the different ways in which you can access the properties of an object. Javascript’s “object oriented” approach is very different from any other language, such as C++ or Java. An object can inherit properties from other objects, but ????? blanking here need to re-read more about this….
      </p>
      <ul>
        <li>(https://www.w3schools.com/js/js_json_objects.asp)</li>
        <li>(https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment)</li>
        <li>(https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining)</li>
        <li>(https://javascript.info/keys-values-entries)</li>
      </ul>
      <h5>
        4. Asynchronous Execution
      </h5>
      <p className='pt-3'>
        Program flow in Javascript is one of those things that will leave even (maybe especially!) experienced coders scratching their heads. Javascript code execution flows from top to bottom, except when it doesn’t. Confused? Good! It is not easy to remember every case of whether something will happen in-line or later, but here’s a guideline: if the execution engine has to wait for something to complete, then it’s asynchronous. This means it gets “saved for later”. Take an example like a network request. It will be called asynchronously because the engine (usually node.js or the browser) has to wait for whatever response comes back over the network. These operations take time, and so the thread executing your program stashes away the intent to complete the log, and comes back to it later.
      </p>
      <p className='pt-3'>
        Why does this happen? Javascript was not originally intended to handle long-running operations. Instead, the priority was building small applications that were quick to respond to user inputs. The common use at first was making dynamic user interface widgets for the web. The first execution environment for Javascript was your web browser window. Since then, it has grown so much in popularity, that they have tacked on Promises and Async/Await syntax to make Javascript behave a bit more like you would expect a synchronous running language to behave. Make no mistake though, that it’s all smoke and mirrors, and you are making heavy use of callback functions when you code in this way. (Nothing wrong with that!) (https://javascript.info/callbacks)
      </p>
      <h5>
        5 NPM and NODE.JS
      </h5>
      <p className='pt-3'>
        Code reuse is a big deal in Javascript. So much so, that the Node Package Manager was created. NPM gives a really easy way to add, track, and share versions for any bit of code, large or small, that you want to include and use in your project. There are two main parts that go in your project when you use NPM to manage it: package.json tracks what you’ve included, and gives a blueprint to anyone new to your project so they know what packages they need to download. This way, you don’t have to include all that code in your version control repository.
      </p>
      <p className='pt-3'>
        There are some downsides to NPM however. First and most worrisome, is that you are dependent on the availability (and security) of someone else’s code. There can also be licensing considerations, if you are working for a business then the packages must be approved before being used. The second concern is that the node_modules directory, the place where NPM stores the source of what you import, can get really large and cumbersome to manage. (ref?)
      </p>
      <h5>
        6. Javascript is always CHANGING!
      </h5>
      <p className='pt-3'>
        Functions are regularly deprecated I have found. There are also new standards coming out all the time. While these are useful, if you already developed an app using an old method, you now will likely want to go back and have to rewrite all your code using the new, more convenient functions. Otherwise, you run the risk of your old code ceasing to work due to deprecation of old APIs and coding styles. Some examples of new functions that are helpful, but feel a bit tacked on are Promises (), Async/Await syntax (), and Optional Chaining (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining). Another side effect of all these constant changes, is that tutorials that are written even as little as one year ago, become no longer best practice, irrelevant, and sometimes even misleading. (My tutorials will always be relevant!) At this point I think I have found more articles whose example code didn’t work for me than I have found ones that did!
      </p>
      <h5>
        7. (BONUS) GIT
      </h5>
      <p className='pt-3'>
        This might be obvious to a lot of people nowadays, but I believe it's worth noting: Do yourself a favor, and learn at least the very most basic git commands. Git has quickly become <strong>essential</strong> to successfully managing a coding project if it’s beyond a couple of files or you intend to remember how you changed your program over time. Without it, history is a blur, and if you delete some part of your code that you later found out you needed, well….  
      </p>
      <hr />
      <p>
        
      </p>
    </PgProjectOutline>
  )
}

export default Project2;