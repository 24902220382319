import React from 'react';
import '~/Common.scss';
import { 
  Row,
  Col,
  Container
  } from 'react-bootstrap';
import FSLSideNavbar from '~/SideNavbar';

function Home() {
  return (
    <React.Fragment>
      {/* PAGE BANNER */}
      <Container fluid='true' className='page-header-bar shadow-lg'>
        <Row>
          <Col>
            <div>
              Welcome to Fritz Acoustics!
            </div>
          </Col>
        </Row>
      </Container>
      {/* MAIN PAGE */}
      <Container className='container-xxl mt-5'>
        <Row>
          <Col md={8}>
            <Row>
              <Col>
                <div className='mt-3 shadow rounded p-3'> {/* From outside to inner, it goes: MARGIN, border, PADDING, content.*/}
                  <h1>Welcome!</h1>
                  <hr />
                  <p>Welcome to my site! This is a place for me to post some of the projects I work on in my free time. I hope you end up finding some of my writing entertaining!</p>
                  <p>Check out my <a href="./about">About Page</a> to find out more about me.</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='mt-3 shadow rounded p-3'>
                  <img className='img-sm-resize' style={{width: "100%"}} src="/images/wave2_400.jpg" alt="sound-wave-2" width="400" height="241" />
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={4}> {/* d-none d-sm-block => prevents display on small screens. pt-5 => padding top 5*/}
            <div className='mt-3 p-3 shadow rounded'>
              <Row className='m-2'>
                  <p className='text-center'>Check out my speaker project, in progress! <a href='/p1'>NE123W Speaker Project</a></p>
              </Row>
              <Row className='m-2'>
                <div><p className='text-center'>Maybe important news!</p></div>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>  
    </React.Fragment>
  )
}

export default Home;