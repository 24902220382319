import React from 'react';
import '~/Common.scss';
import { 
  Row,
  Col
} from 'react-bootstrap';

function ProjectSummaryCard(props) {
  return(
    <Row className='m-4'>
      <Col className='shadow rounded p-3'>
        <p className='text-center'>
          <a href={props.href}>
            <h3 className='text-center'>{props.title}</h3>
          </a>
        </p>
        <p>
          {props.children}
        </p>
      </Col>
    </Row>
  )
}

export default ProjectSummaryCard;