import React, { useState } from 'react';

import '~/Common.scss';
import { Nav,
  NavDropdown,
  NavItem,
  NavLink,
  Navbar,
  Form,
  Dropdown,
  Container,
  Row,
  Col
  } from 'react-bootstrap';


function FSLTopNavbar() {
  const [ searchTerm, setSearchTerm ] = useState('');
  return (
    
    <Container className='bg-gradient-dark shadow-lg'>
      <Navbar defaultactivekey="/home" variant="dark" collapseOnSelect expand="lg" >
        <Navbar.Brand href="/home">
          <img src="/images/speaker-icon-png-1-bl-transparent.png" className="d-inline-block align-top mr-2" width="40" height="40" alt="sound-wave-2" />
          <Navbar.Text className="navbar-header">Fritz Acoustics</Navbar.Text>
        </Navbar.Brand>
        
        <Navbar.Toggle /> 

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
              <NavLink eventKey="/home" href="/home">Home</NavLink>
              <NavLink eventKey="/projects" href="/projects">Projects</NavLink>
              <NavLink eventKey="/about" href="/about">About</NavLink>
          </Nav> {/*
          <Form inline>
            <input class="form-control mr-sm-2" type="search" placeholder="Search site" aria-label="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={() => doSearch(searchTerm)}>Search</button>
          </Form>*/}
        </Navbar.Collapse> 
      </Navbar>
    </Container>
    
  )
}

function doSearch(term) {
  console.log(`Doing search: ${term}`);
}

export default FSLTopNavbar;