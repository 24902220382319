import React from 'react';
import '~/Common.scss';
import '~/SidebarMenu.scss';
import { Nav } from 'react-bootstrap';
import { withRouter } from "react-router";

/***************************************************
 * Side navigation panel. This will disappear on xs screens.
 * I would like this to be collapsible for small screens.
 * I would like this to be filled with content dynamically depending on which page we're on.
 */
function FSLSideNavbar(props) {
  var { links, setPage } = props;
  if (!links) {
    links = [{ name: "", href: "" }]
  }
  return (
    <>
      <Nav className="col-md-12 d-none d-md-block bg-light sidebar"
      activeKey="/home"
      onSelect={selectedKey => setPage(selectedKey)}
      >
        <div className="sidebar-sticky"></div>
        {links.map(linkdata => {
          return (
            <Nav.Item>
              <Nav.Link href={linkdata.href}>{linkdata.name}</Nav.Link>
            </Nav.Item>
          )
        })}
      </Nav>
    </>
  )
}

export default FSLSideNavbar;