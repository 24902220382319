import React from 'react';
import '~/Common.scss';
import { 
  Row,
  Col,
  Container
} from 'react-bootstrap';
import '~/SidebarMenu.scss';
import FSLSideNavbar from '~/SideNavbar';
import ProjectSummaryCard from '~/ProjectSummaryCard';

function ProjectsIndex(props) {
  var projectPageLinks = [
    { name: "Project 1: Speaker", href:"/p1" },
    { name: "Project 2: Javascript Intro", href:"/p2" },
    { name: "Project 3: AVR Networking", href:"/p3" },
  ]
  const setPage = props.setPage;

  return (
    <React.Fragment>
      <div className='page-header-bar shadow-lg'>
        Main Projects Page
      </div>
      <Container fluid='xl' className='px-0'>
        <Row noGutters='true'>
          <Col md={2} id="sidebar-wrapper" className='d-none d-md-block'>
            <FSLSideNavbar links={projectPageLinks} setPage={setPage}/>
          </Col>
          <Col sm={12} md={10} className='pt-5'>
            <ProjectSummaryCard 
                href='/p1' 
                title='3 Way Speaker Project Using NE-123W Midrange'
                date='2022-Apr-8'>
              <img className='img-sm-resize float-right' src="/images/projects/first-speaker/NE123W-render-3.png" alt="Speaker Render" width="200" />
              <p className='pt-3'>I've been learning about speakers since I was a kid. This is my latest design, where I use a variety of skills beyond just acoustic design to build a speaker I am proud of. </p>
              <p className='pt-3'>In this new iteration, I've learned how to use loudspeaker measurement software to measure drivers independently and model crossover effects before the speaker is constructed. I designed a measurement baffle free of early reflections, and learned how to effectively do infinite baffle measurements. </p>
              <p className='pt-3'>I've learned much more about PCB layout techniques (and electronics in general.) The biggest hurdle, and subsequently the biggest jump in cool-ness for this project was the process of learning 3D-CAD, as well as building a CNC machine. You'll see the importance of each of these elements as I walk you through the design process of one of my speakers. </p>
              <p className='pt-3'>I am always adding to and improving this design, and also always learning! So this will be a living article for a while. </p>
            </ProjectSummaryCard>
            <ProjectSummaryCard 
                href='/p2' 
                title='Javascript Intro for Programmers'
                date='2022-Apr-8n'>
              <img className='img-sm-resize float-right' src="/images/projects/jsintro/jscup.png" alt="Java (script)" width="200" />
              <p className='pt-3'>If you are familiar with C, C++, Java, other procedural languages like I am, then you might also find Javascript puzzling like I did. There is a lot of talk of "asynchronous" and "event driven", but there is little practical, high level advice out there. </p>
              <p>If you are trying to get into web server or web app development, this article aims to fast-track you at the start of your Javascript journey by explaining some of the first quirks that I had trouble with when I started learning Javascript in December 2019.</p>
            </ProjectSummaryCard>
            <ProjectSummaryCard 
                href='/p3' 
                title='AVR Network for Cinema Room Gadgets'>
              <img className='img-sm-resize float-right' src="/images/projects/avrnet/atmega328p.png" alt="Atmega328p" width="200" />
              <p className='pt-3'>I had a lot of fun developing this RS-485 based network. The goal with this project has been to build several different types of automated fixtures to put in my home theater room. The controlling node for this network is a PC with a NodeJS web server that can send commands over the network.</p>
            </ProjectSummaryCard>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ProjectsIndex;