import React from 'react';
import '~/Common.scss';
import { 
  Row,
  Col,
  Container
  } from 'react-bootstrap';
import FSLSideNavbar from '~/SideNavbar';
import '~/SidebarMenu.scss';

function About(props) {
  const sideNavLinks = [
    { name: "Home", href:"/home" },
    { name: "Projects", href:"/projects" },
    { name: "Project 1", href:"/p1" },
  ]
  const setPage = props.setPage;

  return (
    <React.Fragment>
      <div className='page-header-bar shadow-lg'>
        About Fritz Acoustics
      </div>
      <Container fluid='xl' className='px-0'>
        <Row noGutters='true'>
          <Col md={2} id="sidebar-wrapper" className='d-none d-md-block'>
            <FSLSideNavbar
              links={sideNavLinks} setPage={setPage}
            />
          </Col>
          <Col sm={10} className='pt-5'>
            <Row className='m-4'>
              <Col className='shadow rounded p-3 px-5'> {/* From outside to inner, it goes: MARGIN, border, PADDING, content.*/}
                <h1> About Me </h1>
                <p className='text-justify'>
                  Hello World. My name is Chris Fritz, and this page exists to host information about all of the various projects I undertake. By day I am a software engineer at IBM. All of my projects here are hobbies that I do in my spare time, and are unrelated to IBM in any way. Hopefully something ends up being interesting on here!
                </p>
              </Col>
            </Row>
            <Row className='m-4'>
              <Col className='shadow rounded p-3 px-5'> {/* From outside to inner, it goes: MARGIN, border, PADDING, content.*/}
                <h1> Ideas that I would like to post about:</h1>
                <ul className='text-left'>
                  <li>My experience with active speaker crossovers (mostly based on <a href='http://www.linkwitzlab.com/filters.htm'>linkwitzlab</a>)</li>
                  <li>Speaker measurement process (I use Soundeasy, and have CNC manufactured a measurement baffle)</li>
                  <li>AVR command protocol: I would like to write about the process of how I designed it, and do a walk through of how a command is processed.</li>
                  <li>AVR pogo-pin-header programmer - this is a simple hardware-spec that I created to use pogo-pin headers (<a href='https://www.digikey.com/en/products/detail/preci-dip/813-S1-006-10-017101/4131286'>2x3 on Digikey</a>, <a href='https://www.digikey.com/en/products/detail/mill-max-manufacturing-corp/821-22-006-10-000101/1146513'>1x6 on Digikey</a>)</li>
                  <li>Photography - basics? flash usage? This one is way out of the "norm" of what I want to post about here, but I have studied a lot about the technical aspects of photo-taking and could have a whole sub-section of the site dedicated to this.</li>
                  <li>...What else is cool???</li>
                </ul>
                <p className='text-left'>
                  Reminder to self: If I do tutorials, some important points to touch on: 
                  <ul className='text-left'>
                    <li>Why it's important to learn topic and why this tut is different</li>
                    <li>What are some things you're already going to need to know. Maybe put a list of topics/links to edu.</li>
                    <li>For each code block, say in plain language what it's purpose is, as well as any tricks I may have used. Try to avoid using advanced sugar-coating like destructuring. Also avoid using repeated or vague variable names. That's so annoying.</li>
                    <li>Include a Code playground or live example link, or at least a github repo link.</li>
                  </ul>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default About;